import closedLoopIcon from '../assets/closed_loop.png'
import cancelledIcon from '../assets/cancelled.png'
import { MAX_EVALUATION_CANCEL_TIMES } from './config';

const CancelledPage = ({ evaluation }) => {
    const finalCancel = (evaluation?.cancel_times || 0) >= MAX_EVALUATION_CANCEL_TIMES;
    const icon = finalCancel ? cancelledIcon : closedLoopIcon;
    const secondaryText = finalCancel ? `To re-start the test, please contact ${evaluation.user_name} for a new link.`
        : "To re-start the test, please check the inbox of your registered email and follow the instructions";
    return (
        <div>
            <div>
                <img src={icon} style={{ maxWidth: "120px", marginTop: "100px", marginBottom: "30px" }} alt="" />
            </div>
            <h1 style={{ fontWeight: '700', fontSize: '40px', fontFamily: "Inter", lineHeight: "48px" }} >Thanks for your input</h1>
            <p style={{ marginTop: '30px' }}>
                Your test has been cancelled and no data was captured.
            </p>
            <p style={{ maxWidth: "470px", marginLeft: "10px" }}>
                {secondaryText}
            </p>
        </div>
    );
};

export default CancelledPage;