import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { getEvaluationsTableColumns } from './EvaluationsTableColumns';
import DataTable from "react-data-table-component";
import './Dashboard.css'

const customStyles = {
    tableWrapper: {
        style: {
            overflowX: 'auto', // Ensure horizontal scrolling
        },
    },
    table: {
        style: {
            tableLayout: 'auto', // Allow columns to fit content
        },
    },
    headCells: {
        style: {
            justifyContent: 'center', // Center the header text
            paddingLeft: '25px',
        },
    },
    cells: {
        style: {
            justifyContent: 'center', // Center the cell content
        },
    },
};

const EvaluationsTable = ({ evaluations, error, handleAddEvaluation, user }) => {
    return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className='table-container'>
                <DataTable
                    columns={getEvaluationsTableColumns(user)}
                    data={evaluations}
                    responsive={true}
                    customStyles={customStyles}
                    pagination
                    striped
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
                    actions={<Button onClick={handleAddEvaluation}>Add New Test</Button>}
                />
            </div>
        </div>
    );
};

export default EvaluationsTable;
