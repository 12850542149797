import { joinUrl } from '../util';
import { Button, Badge } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Dashboard.css'
import { EvaluationStatus } from '../consts';

export function formatIrishDateTime(started_at, showYear = true) {
    if (!started_at) {
        return '';
    }
    let date_config = {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };

    if (showYear) {
        date_config = { ...date_config, year: 'numeric' }
    }

    return new Date(started_at).toLocaleString('en-IE', date_config)
}

const evaluation_statuses_display = {
    [EvaluationStatus.WELCOME]: "WELCOME",
    [EvaluationStatus.NOT_STARTED]: "NOT STARTED",
    [EvaluationStatus.RUNNING]: "RUNNING",
    [EvaluationStatus.SUCCEEDED]: "SUCCEEDED",
    [EvaluationStatus.FAILED]: "FAILED",
    [EvaluationStatus.FAILED_VEHICLE_ALREADY_RUNNING]: "FAILED - VEHICLE ALREADY RUNNING",
    [EvaluationStatus.CANCELLED]: "CANCELLED"
};

const smallButtonStyle = {
    fontSize: '12px',
    padding: '5px 10px',
    width: '100px',
    textAlign: 'center',
};

// Column configurations for regular users
let regularUserColumns = [
    {
        name: "Create date",
        selector: (row) => formatIrishDateTime(row.created_at),
        sortable: true,
        sortFunction: (rowA, rowB) => new Date(rowA.created_at) - new Date(rowB.created_at),
        width: '135px'
    },
    {
        name: (
            <div>
                Vehicle make
            </div>
        ),
        selector: (row) => row.vehicle?.make?.value || '',
        sortable: true,
        width: '130px'
    },
    {
        name: (
            <div>
                Vehicle model
            </div>
        ),
        selector: (row) => row.vehicle?.model?.value || '',
        sortable: true,
        width: '150px'
    },
    {
        name: (
            <div>
                Vehicle year
            </div>
        ),
        selector: (row) => row.vehicle?.year?.value || '',
        sortable: true,
        width: '120px'
    },
    {
        name: "Status",
        selector: (row) => {
            const statusDisplay = evaluation_statuses_display[row.status];
            if (statusDisplay.startsWith("FAILED -")) {
                const [status, ...tooltipParts] = statusDisplay.split(" - ");
                const tooltip = tooltipParts.join(" - ");
                return (
                    <div title={tooltip}>
                        <Badge bg="primary">{status}*</Badge>
                    </div>
                );
            } else {
                return (
                    <div>
                        <Badge bg="primary">{statusDisplay}</Badge>
                    </div>
                );
            }
        },
        wrap: true,
        sortable: true,
        center: 'true',
        sortFunction: (a, b) => { if (a.status > b.status) return -1; if (a.status < b.status) return 1; return 0; },
        width: '130px'
    },
    {
        name: "Start time",
        selector: (row) => formatIrishDateTime(row.started_at),
        sortable: true,
        sortFunction: (rowA, rowB) => new Date(rowA.started_at) - new Date(rowB.started_at),
        width: '135px'
    },
    {
        name: "Finish time",
        selector: (row) => formatIrishDateTime(row.finished_at),
        sortable: true,
        sortFunction: (rowA, rowB) => new Date(rowA.finished_at) - new Date(rowB.finished_at),
        width: '135px'
    },
    {
        name: (
            <div>
                Reports &nbsp;&nbsp;
            </div>
        ),
        selector: (row) => (
            (
                row.status === EvaluationStatus.NOT_STARTED ||
                row.status === EvaluationStatus.WELCOME ||
                row.status === EvaluationStatus.RUNNING ||
                row.status === EvaluationStatus.CANCELLED
            ) ?
                <div>
                    <LinkContainer to={joinUrl('/get-url', row.code)} className="p-1">
                        <Nav.Link>
                            <Button variant="primary" style={smallButtonStyle}>
                                Test URL
                            </Button>
                        </Nav.Link>
                    </LinkContainer>
                </div> :
                (
                    row.status === EvaluationStatus.SUCCEEDED ?
                        <LinkContainer to={joinUrl('/report', row.code)} className="p-1">
                            <Nav.Link>
                                <Button variant="primary" style={smallButtonStyle}>
                                    View Report
                                </Button>
                            </Nav.Link>
                        </LinkContainer> : null
                )
        ),
        width: '135px'
    },
];


regularUserColumns = [
    ...regularUserColumns.map((col) => ({
        ...col,
        style: { fontSize: '12px' },
        center: 'true',
    }))];

const adminUserColumns = [
    ...regularUserColumns.map((col) => ({
        ...col,
        // width: adjustWidthForAdmin(col.width),
    })),
    {
        name: (
            <div>
                Company<br />name
            </div>
        ),
        selector: (row) => row.user.name,
        sortable: true,
        width: '130px'
    },
];

function adjustWidthForAdmin(width) {
    const widthPercentage = parseFloat(width.replace('%', ''));
    const adjustedPercentage = (widthPercentage * 0.9).toFixed(1);
    return `${adjustedPercentage}%`;
}

export function getEvaluationsTableColumns(user) {
    return user.role === 'admin' ? adminUserColumns : regularUserColumns;
}
