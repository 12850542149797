import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './StandaloneMessagePage.css';
import loginIcon from '../assets/loginicon.png'

const StandaloneMessagePage = ({ h1Text, pText, icon, button = null }) => {
    return (
        <div className="standalone-success-container">
            <div className="standalone-content">
                <div className="standalone-icon-wrapper" >
                    <img className="standalone-icon" src={icon} alt="" />
                </div>
                <h1>{h1Text}</h1>
                <p>
                    {pText}
                </p>
                {button || <LinkContainer to="/login">
                    <Nav.Link className='go-to-link'>  <img src={loginIcon} alt="login icon" className='icon-login' /> {"Go to Login"} </Nav.Link>
                </LinkContainer>}
            </div>
        </div>
    );
};

export default StandaloneMessagePage;
