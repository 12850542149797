import linkExpiredIcon from '../assets/link_expired.png'

const Expired = ({ evaluation }) => {
    return (
        <div className="row justify-content-center">
            <img src={linkExpiredIcon} style={{ maxWidth: "120px", marginTop: "100px", marginBottom: "30px" }} alt="" />
            <h1 style={{ fontWeight: '700', fontSize: '40px', fontFamily: "Inter", lineHeight: "48px" }} >Link Expired</h1>
            <p style={{ maxWidth: "500px", marginTop: '30px' }}>
                The link you've attempted to access is no longer active.
            </p>
            <p style={{ maxWidth: "970px" }}>
                {`Please contact ${evaluation.user_name} to request a new link to initiate a fresh test.`}
            </p>
        </div>
    );
}

export default Expired