import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EvaluationsApi from '../apis/Evaluations';
import Loading from '../shared/Loading';
import { joinUrl } from '../util';
import Expired from './Expired';


const evaluationsApi = new EvaluationsApi(null, null, false);

const Continue = () => {
    const { evaluationCode } = useParams();
    const [expiredEvaluation, setExpiredEvaluation] = useState(null)

    useEffect(() => {
        if (evaluationCode) {
            evaluationsApi.reset(evaluationCode).then(r => {
                if (r?.data?.expired) {
                    setExpiredEvaluation(r?.data?.evaluation)
                } else {
                    window.location.href = joinUrl(window.location.origin, `evaluations/`, evaluationCode)
                }
            })
        }
    }, [evaluationCode])

    return expiredEvaluation ? <Expired evaluation={expiredEvaluation} /> : <Loading />
}

export default Continue