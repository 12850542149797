import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import './WelcomePage.css';
import avatarIcon from '../assets/avatar.png';
import batteryIcon from '../assets/charging-battery.png';
import { getSmartcarAuthURL } from './SmartcarUtils';

const WelcomePage = ({ evaluation }) => {
    const handleContinue = () => {
        getSmartcarAuthURL(evaluation.code).then(smartcarAuthURL => {
            window.location.href = smartcarAuthURL;
        });
    };

    return (
        <div className="rbh-container welcome-screen" style={{ padding: '50px 20px', textAlign: 'center' }}>
            <Row className="justify-content-center">
                <h1 className="welcome-header" style={{ fontWeight: 'bold', fontSize: '2.5rem' }}>Welcome</h1>
                <Row className="justify-content-center mb-5">
                    <p className="welcome-text">
                        To begin the battery check, click the button below to proceed. You’ll be <br /> redirected securely to Smartcar to connect your vehicle.
                    </p>
                </Row>
                <div className="d-flex align-items-center justify-content-center mb-3" style={{ gap: '30px' }}>
                    <div className='d-flex'>
                        <img src={avatarIcon} alt="User Icon" style={{ width: '24px', height: '25px', marginRight: '10px', marginTop: '10px' }} />
                        <span className="icon-description">
                            You need your username and password for your brand account e.g. VW, BMW, Tesla etc.
                        </span>
                    </div>
                    <div className='d-flex'>
                        <img src={batteryIcon} alt="Battery Icon" style={{ width: '30px', height: '20px', gap: '10px', marginRight: '10px', marginTop: '13px' }} />
                        <span className="icon-description">
                            To complete the test your battery percentage remaining should be no more than 75%.
                        </span>
                    </div>
                </div>
                <p className="info-text" style={{ marginTop: '20px', fontSize: '1rem' }}>
                    You have received this link from <span style={{ fontWeight: 'bold' }}>{evaluation.user_name}</span> as part of an evaluation of your vehicle.
                </p>
                <Button variant="primary" className="mt-2 continue-btn" onClick={handleContinue}>
                    Continue
                </Button>
            </Row>
        </div>
    );
};

export default WelcomePage;
