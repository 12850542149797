import ConfirmationPopup from '../shared/ConfirmationPopup';
import cancelIcon from '../assets/cancel.png'
import { MAX_EVALUATION_CANCEL_TIMES } from './config';

const CancelEvaluationModal = ({ evaluation, handleAffirmative, showModal, setShowModal }) => {
    const cancelTimes = evaluation?.cancel_times || 0

    return (
        <ConfirmationPopup
            showModal={showModal}
            setShowModal={setShowModal}
            title={"Confirm Cancellation"}
            bodyDesc={
                "Are you sure you want to cancel battery assessment?" +
                (
                    cancelTimes === MAX_EVALUATION_CANCEL_TIMES - 1 ?
                        ` To continue you need to request a link from ${evaluation.user_name}` :
                        ""
                )
            }
            icon={cancelIcon}
            handleAffirmative={handleAffirmative}
        />
    );
}

export default CancelEvaluationModal